.call-to-action{
  margin: 0;
  padding: 3rem 0 2rem 0;
}

.cta--inner{
  display: flex;
  width: 100%;
  font-size: 2.5rem;

  @media @tabL{
    font-size: 2rem;
  }

  @media @smL{
    flex-direction: column;
    font-size: 1.75rem;
  }

  .cta--heading,
  .cta--number{
    width: 50%;

    @media @smL{
      width: 100%;
      text-align: center;
    }
  }

  .cta--heading span{
    display: block;
    text-align: right;
    padding-right: 2rem;

    @media @smL{
      text-align: center;
      padding-right: 0;
    }
  }
  .cta--number a{
    color: @warningColor;
    padding-left: 2rem;
    @media @smL{
      text-align: center;
      padding-left: 0;
    }
  }
}

.cta--subline{
  font-size: 1.75rem;
  font-weight: 500;
  text-align: center;
  padding-right: 3rem;
  padding-bottom: 2rem;

  @media @momentToShowTheHamburger{
    padding-right: 0;
    font-size: 1.25rem;
  }
}

#conversionbar{

  background: url("../content/index/img/conversion-bg.jpg") no-repeat center center;
  background-size: cover;
  min-height: 480px;

  .content80C{
    .contentElement{
      text-align: center;

      h2{
        margin-top: 200px;
        color: @white;
      }

      a{
        padding: 1rem 2rem;
        background: @whiteGrey;
        color: @dark;
        font-size: 1.25rem;
        font-weight: 500;
        letter-spacing: 1px;

        .fa{
          margin-left: 1rem;
        }
      }
    }
  }
}