main .row, .container > .headingCol{
min-height: 44px;
}

main a{
  font-weight: 400;
  color: @black;
}


main .has--primary-color a{
  color: @white;
}