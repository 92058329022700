/**

  ALL PARAGRAPH CLASSES


 */

main p{
  margin-bottom: 1.5rem;
}

// Two possible additional Classes can be set in Edith Mode:
// the wrappers are <div> around the Parapraph(s)

// highlighted Parapgraph
.paragraph--highlighted{
  padding: 1rem;
  border-top: 1px solid @tertiaryColor;
  border-bottom: 1px solid @tertiaryColor;
  margin-top: 1rem;
  margin-bottom: 1rem;
  color: @black;

  p{
    margin: 0;

    em{
      color: @medGrey;
    }
  }
}

// and exposed Paragraph
.paragraph--exposed{
  font-style: italic;
  font-size: 1.33rem;
  color: @primaryColor;
  font-weight: 500;
  padding: 1rem 2rem;
}