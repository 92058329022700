/**

  ALL BUTTON CLASSES


 */

// button's wrapper | type DIV
.button--wrapper{}

// wrapper's modifier in case of given additional classes
.button--hasExtraClasses{}
// each additional class will appear as .button--hasExtraClass-%addClass%

// wrapper's modifier in case of external Link
.button--external{}

// the button itself | type A
.button{
  padding: 1rem;
  .interactionTransition();

  // in edith you can add the three main colors to the button:
  &.button--primary{
    background: @primaryColor;
    color: @white;

    &:hover,
    &:active{
      background: @secondaryColor;
    }
  }

  &.button--secondary{
    background: @secondaryColor;
  }

  &.button--tertiary{
    background: @tertiaryColor;
  }
}
// each additional class will appear after button class on the link
