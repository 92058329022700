@import url('https://fonts.googleapis.com/css?family=Sulphur+Point:300,400,700&display=swap');


/**
      COLOR standard settings
 */

@stdTextColor:        #343434;
@black:               #000;
@white:               #fff;
@whiteGrey:           #f0f0f0;
@lightGrey:           #b0b1ab;
@medGrey:             #505050;
@darkMedGrey:         #2f2f2f;
@darkGrey:            #1c1c1c;
@dark:                #161616;

@warningColor:        #e6261d;
@linkColor:           @lightGrey;

// IMPORTANT!! Define the same colors here like in Edith's BE!
@primaryColor:        var(--primaryColor);
@secondaryColor:      var(--secondaryColor);
@tertiaryColor:       var(--tertiaryColor);

@topBarHeight: 120px;
@topBarScrolledHeight: 80px;
@navBarWidth: 16vw;

@navPaddings:         3rem;
@navFontSize:         1.25rem;
@navItemHeight:       52px;


/**
      TYPOGRAPHY standard settings
 */

@stdFont: "Fira Sans", Helvetica, Calibri, sans-serif;
@headlineFont:    "Sulphur Point", Helvetica, Calibri, sans-serif;
@h1size:          2rem;
@h2size:          2.25rem;
@h2sizeMobile:    1.875rem;
@h3size:          2rem;
@h3sizeMobile:    1.5rem;
@h4size:          1.25rem;
@h5size:          1rem;
@h6size:          1rem;

@stdFontSize:     1rem;
@stdLineHeight:   1.5;





/**
      LAYOUT standard settings
 */

@maxwidth: 1280px;

@rowStdMargin:  5rem;

@momentToShowTheHamburger:  @tabP;

@macB:        ~"only screen and (max-width: 1442px)";     // MacB == drecks Macbooks
@tabL:        ~"only screen and (max-width: 1279px)";     // tabL == tablet in Landscape
@tabP:        ~"only screen and (max-width: 853px)";      // tabL == tablet in Portrait
@tabP_only:   ~"only screen and (max-width: 853px) and (min-width: 621px)";
@smL:         ~"only screen and (max-width: 620px)";      // smL == smartphone in Landscape
@smP:         ~"only screen and (max-width: 480px)";      // smP == smartphone in Portrait
@smX:         ~"only screen and (max-width: 384px)";      // smX == very small smartphones

/* usage:

   @media @smL{
      your-css: declarations;
   }

*/