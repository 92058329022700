.slide--inner{
  background: @white;
  display: flex;
  position: relative;

  .slide--image{
    width: 100%
  }

  .slide--caption{
    position: absolute;
    top: 10%;
    left: 2rem;
    height: 80%;
    width: 33%;
    box-sizing: border-box;
    padding: 3rem 4rem;
  }
}


// Prev / Next Arrows
.slick-prev{
  width: 44px;
  height: 50px;
  background: rgba(0,0,0,0.4);
  border: 0;
  position: absolute;
  bottom: 45%;
  left: 0;
  z-index: 333;
  overflow: hidden;
  text-indent: 100px;
  opacity: 0.5;
  cursor: pointer;
  .interactionTransition();

  &:after{
    text-indent: 0;
    position: absolute;
    display: block;
    top: 1.25rem;
    left: 1rem;
    font-family: "fontawesome";
    content: "\f053";
    color: @white;
  }

  &:hover{
    opacity: 1;
  }
}
.slick-next{
  width: 44px;
  height: 50px;
  background: rgba(0,0,0,0.4);
  border: 0;
  position: absolute;
  bottom: 45%;
  right: 0;
  text-indent: 100px;
  overflow: hidden;
  opacity: 0.5;
  cursor: pointer;
  .interactionTransition();

  &:after{
    text-indent: 0;
    position: absolute;
    display: block;
    top: 1.25rem;
    right: 1rem;
    font-family: "fontawesome";
    content: "\f054";
    color: @white;
  }

  &:hover{
    opacity: 1;
  }
}