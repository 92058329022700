// basic styles: font-size, line-height, margins etc
h1{
  font-size: @h1size;
  font-family: @headlineFont;
  margin-bottom: 2rem;
}
h2{
  font-size: @h2size;
  font-family: @headlineFont;
  color: @dark;
  margin-bottom: 1rem;

  @media @smL{
    font-size: @h2sizeMobile;
  }
}
h3{
  font-size: @h3size;
  font-family: @headlineFont;
  color: @medGrey;
  margin-top: 3rem;
  margin-bottom: 1rem;

  @media @smL{
    font-size: @h3sizeMobile;
  }
}
.has--primary-color h3{
  color: @white;
}
h2 + h3{
  margin-top: -1rem;
  margin-bottom: 3rem;
}
h4{
  font-size: @h4size;
  margin-bottom: 1rem;
}
h5{
  font-size: @h5size;
  margin-bottom: 1rem;
}
h6{
  font-size: @h6size;
  margin-bottom: 1rem;
}


header{

  h1{}
  h2{}
  h3{}
  h4{}
  h5{}
  h6{}
}


main{

  h1{}
  h2{}
  h3{}
  h4{}
  h5{}
  h6{}

}

.has--primary-color{

  h1{}
  h2{}
  h3{}
  h4{}
  h5{}
  h6{}
}

.has--secondary-color{

  h1{}
  h2{}
  h3{}
  h4{}
  h5{}
  h6{}
}

.has--tertiary-color{

  h1{}
  h2{}
  h3{}
  h4{}
  h5{}
  h6{}
}