header{
  padding-top: 120px;
  .interactionTransition();



  .header--navigation{
    width: 80%;
  }




  // Mobile Styles
  @media @tabP{
      padding-top: 80px;

    .header{
      height: 80px;
    }


    .header--identity{
      width: 66%;

      img{
        width: 100%;
      }
    }
  }
}


.scrolled{

  header{

    padding-top: 50px;

    .header{
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 50px;
      border-bottom: 2px solid @white;
      box-shadow: 0 0 2px rgba(0,0,0,0.6);
      z-index: 444;

      @media @smL{
        height: 80px;
      }
    }

    .header--identity{

      img{
        height: 50px;
      }
    }
  }
}



.homeheader .content100C{

  position: relative;

  h1{
    padding: 3rem 4rem;
    background: @darkMedGrey;
    color: @white;
    position: absolute;
    bottom: 0;
    margin-bottom: 0;
    display: block;
    width: 100%;;
    box-sizing: border-box;
    left: 0;
    text-align: center;
    font-size: 3rem;

    &:after{
      height: 8px;
      width: 400px;
      position: absolute;
      left: 50%;
      margin-left: -200px;
      bottom: 2.75rem;
      content: "";
      display: block;
      background: @secondaryColor;
    }

    @media @tabL{
      padding: 2rem;

      &:after{
        bottom: 1.75rem;
      }
    }

    @media @smL{
      position: static;
      font-size: 2rem;

      &:after{
        bottom: 0;
        width: 100%;
        left: 0;
        margin-left: 0;
      }
    }
  }
}