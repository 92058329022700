.website--footer{

  font-size: 0.875rem;
  background: @stdTextColor;
  color: @lightGrey;
  padding: @rowStdMargin 0;
  margin: 0;

  p{
    margin-bottom: 0.875rem;
  }

  @media @smL{
    .content33L{
      margin-bottom: 3rem;
    }
  }
}



.footer--nav{

  border-bottom: 1px solid @medGrey;
  padding: 1rem 0;
  display: table;
  width: 100%;
  text-align: right;

  @media @tabP{
    text-align: center;
    padding-bottom: 0;
    margin: 3rem 0;
  }

  li{
    display: inline;
    margin-left: 1.5rem;

    @media @macB{
      display: inline-block;
      width: 50%;
      margin: 0;
      text-align: center;
      margin-bottom: 1rem;
    }

    @media @tabP{
      display: inline-block;
      width: auto;
      margin-left: 1rem;
      text-align: center;
      margin-bottom: 1rem;
    }

    @media @smL{
      display: block;
      margin-left: 0;
      margin-bottom: 0;
      width: 50%;
      float: left;
      border-right: 1px solid @medGrey;
      border-top: 1px solid @medGrey;
      box-sizing: border-box;

      &:nth-child(2),
      &:nth-child(4){
        border-right: 0;
      }
    }
  }

  a{
    .footerlink();

    @media @macB{
      padding: 0.5rem;
    }

    @media @smL{
      padding: 0.5rem 1rem;
      display: block;
      box-sizing: border-box;
      width: 100%;
    }
  }
}

.footer-table tr td:first-child{
  padding-right: 1rem;
}




.subfooter{
  margin: 0;
  padding: 3rem 0;
  background: @whiteGrey;

  .partner--logo{
    text-align: center;
    margin-top: 2rem;
  }
}



.honor{
  padding: 2rem 0;
  text-align: right;

  @media @smL{
    text-align: center;
  }
}