// disable grids standard padding for divs inside .container
.navigation{
  padding: 0;
  position: fixed;
  .navBarWidth();
  background: @medGrey;
  top: @topBarHeight;
  left: 0;
  bottom: 0;
  z-index: 466;
  font-family: @headlineFont;
  display: flex;
  align-items: center;
  .interactionTransition();
}

.scrolled .navigation{
  top: @topBarScrolledHeight;
}



// Standard Navigation Styles
.navigation{

  .menu--mainnav{
    width: calc(@navBarWidth + 3rem);
    min-width: 150px;
    display: table;
    background: @dark;
    padding: @navPaddings 0;
    position: relative;
    .interactionTransition();

    li{
      padding: 0 3rem;
    }

    @media @tabL{
      width: calc(@navBarWidth + 2rem);
      min-width: @navBarWidth;

      li{
        padding: 0 3rem 0 1rem;
      }
    }

    // li.item--hasChild
    .item--hasChild{
      padding-right: 0;
      height: @navItemHeight;

      & > a{
        float: left;
        width: calc(100% - 3rem);
        box-sizing: border-box;
      }
    }

    a{
      display: block;
      height: @navItemHeight;
      box-sizing: border-box;
      padding: 0 1rem 0 1rem;
      text-align: right;
      font-size: @navFontSize;
      font-weight: 300;
      letter-spacing: 1px;
      line-height: @navItemHeight;
      color: @linkColor;
      border-bottom: 1px solid @medGrey;
      .interactionTransition();

      &:hover{
        color: @white;
      }

      @media @tabL{
        font-size: 1rem;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
      }
    }

    li:last-child > a{
      border-bottom: 0;
    }

    // li.active
    .active{

      & > a{
        font-weight: 500;
      }
    }

    .trigger--submenu{
      float: right;
      color: @lightGrey;
      text-align: center;
      width: 3rem;
      height: @navItemHeight;
      border: 0;
      background: none;
    }



    // Submenu Styles
    .item--submenu{
      position: absolute;
      z-index: 999;
      top: 0;
      bottom: 0;
      left: 100%;
      height: 100%;
      padding: @navPaddings 2rem;
      box-sizing: border-box;
      background: @whiteGrey;
      box-shadow: 1px 1px 3px rgba(0,0,0,0.3);
      width: auto;
      display: table;
      white-space: nowrap;
      visibility: hidden;
      -webkit-transform: translate3d(1.5rem, 0, 0);
      -moz-transform: translate3d(1.5rem, 0, 0);
      transform: translate3d(1.5rem, 0, 0);
      opacity: 0;
      .interactionTransition();

      li{
        display: table;
        width: 100%;
        padding: 0 1rem;
        box-sizing: border-box;
      }
      a{
        text-align: left;
        padding: 0;
        height: auto;
        font-size: 1.125rem;
        line-height: @navItemHeight;
        color: @dark;
        border-bottom: 0;

        &:hover, &:active, &:focus{
          color: @black;
        }
      }
    }

    .submenu--in .item--submenu,
    .submenu--fixed .item--submenu{
      z-index: 999;
      visibility: visible;
      opacity: 1;
      -webkit-transform: translate(0, 0);
      -moz-transform: translate(0, 0);
      transform: translate(0, 0);
    }
  }
}



// Smaller Navigation Bar in case of having scrolled down
.scrolled .navigation .menu--mainnav{

  width: @navBarWidth;
  display: block;

  li{
    padding: 0 0.7rem;
  }

  @media @tabL{
    a{
      text-align: left;
    }
  }

  .trigger--submenu {
    margin-top: 6px;
    height: 46px;
    border-bottom: 1px solid @medGrey;
  }
}






// hide subnav trigger in case of beeing able to hover
//.can--hover .navigation .menu--mainnav .trigger--submenu{display: none;}










@media @momentToShowTheHamburger{

  // Mobile Menu - slided out

  .header--navigation{

    position: fixed;
    top: 80px;
    left: 0;
    width: 45%;
    height: ~"calc(100vh - 80px)";
    display: block;
    z-index: 999;
    -webkit-transform: translate3d(-101%,0,0);
    -moz-transform: translate3d(-101%,0,0);
    -ms-transform: translate3d(-101%,0,0);
    -o-transform: translate3d(-101%,0,0);
    transform: translate3d(-101%,0,0);
    background: @white;
    .interactionTransition();

    .menu--mainnav{
      li{
        width: 100%;
        display: block;

        a{
          display: block;
          padding: 0 1rem;
          line-height: 44px;
          height: 44px;
          width: 100%;
        }


        &.item--hasChild{

          display: table;
          width: 100%;

          a{
            width: calc(100% - 3rem);
            float: left;
            //display: block;
          }

          .trigger--submenu{
            width: 3rem;
            height: 44px;
            float: right;
            margin-top: 0;
            display: inline-block;
            text-align: center;
          }
        }
      }
    }
  }

  // Mobile Menu - slided in
  .off-canvas--in{

    .header--navigation{
      -webkit-transform: translate3d(0,0,0);
      -moz-transform: translate3d(0,0,0);
      -ms-transform: translate3d(0,0,0);
      -o-transform: translate3d(0,0,0);
      transform: translate3d(0,0,0);
    }

  }

  // second level slides
  .navigation .menu--mainnav{
    position: relative;
    width: 100%;
    height: 100%;

    li{
      width: 100%;
      box-sizing: border-box;
    }

    a{
      //border: 0;
    }

    .item--hasChild{
      position: static;
    }


    .item--submenu{
      position: absolute;
      top: 0;
      left: 0;
      width: 80%;
      max-width: 80%;
      background: #bbb;
      height: ~"calc(100vh - 80px)";
      bottom: 0;
      visibility: visible;
      opacity: 1;
      -webkit-transform: translate3d(-102%,0,0);
      -moz-transform: translate3d(-102%,0,0);
      -ms-transform: translate3d(-102%,0,0);
      -o-transform: translate3d(-102%,0,0);
      transform: translate3d(-102%,0,0);

      li a{
        text-align: left;
      }
    }
  }

}


@media @smL{
  .header--navigation{
    width: 85%;
  }
}

@media @smX{
  .navigation  .menu--mainnav .item--submenu{
    padding: 3rem 1.2rem;
  }
}
