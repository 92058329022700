body{
  font-family: @stdFont;
  font-size: 1.125rem;
  color: @stdTextColor;
  line-height: @stdLineHeight;
}

.full-width .wrapper{
  margin-left: @navBarWidth;
  width: calc(100% - @navBarWidth);

  @media @tabP{
    margin-left: 0;
    width: 100%;
  }
}



strong{
  font-weight: 600;
}


.centered{
  text-align: center;
}





.full-width{

  // makes elements of row.flex are aligned in the middle of the row
  .flex{

    width: 100%;
    & > .container{

      display: flex;
      align-items: center;

      @media @tabP{
        // since tabletPortrait flex items should appear as column
        flex-direction: column;
      }
    }
  }
}


@media @tabP{

  .full-width .mobile--reverse > .container,
  .full-width .mobileReverse > .container{
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
  }
}




/**

    Slide back to top button

 */
.scroller {
  width: 2em;
  height: 2em;
  background: rgba(0,0,0,0.2) url('../core/img/toparr.svg')no-repeat center center;
  background-size: 50% auto;
  color: @white;
  position: fixed;
  z-index: 222;
  bottom: 2em;
  left: -2em;
  -moz-transition: 0.3s ease;
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
  overflow: hidden;
  display: block;
  text-indent: 100px;
  white-space: nowrap;
}
.scroller.come-in {
  left: 1em;
}