.modalOverlay{
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  background: rgba(0,0,0,0);
  transition: background 0.3s ease;
}

.modalOpened .modalOverlay{
  position: fixed;
  width: auto;
  height: auto;
  z-index: 901;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0,0,0,0.4);
}

.modal{
  width: @maxwidth;
  height: 80vh;
  position: fixed;
  z-index: 920;
  display: block;
  top: 10vh;
  left: 50vw;
  margin-left: calc(-@maxwidth / 2);
  background: @white;
  transform: translate(0, -100vh);
  transition: 0.5s ease-out;
  visibility: hidden;
  opacity: 0;
}
.modal.open{
  transform: translate(0,0);
  visibility: visible;
  opacity: 1;
}