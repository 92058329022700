
.halfSecondTransition(){
  -webkit-transition: 0.5s ease-out;
  -moz-transition: 0.5s ease-out;
  -ms-transition: 0.5s ease-out;
  -o-transition: 0.5s ease-out;
  transition: 0.5s ease-out;
}

.oneSecondTransition(){
  -webkit-transition: 1s ease-out;
  -moz-transition: 1s ease-out;
  -ms-transition: 1s ease-out;
  -o-transition: 1s ease-out;
  transition: 1s ease-out;
}

.interactionTransition(){
  -webkit-transition: 0.33s ease;
  -moz-transition: 0.33s ease;
  -ms-transition: 0.33s ease;
  -o-transition: 0.33s ease;
  transition: 0.33s ease;
}


.navBarWidth(){
  width: 16vw;
}


.footerlink(){
  font-family: @stdFont;
  color: @white;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;

  &:hover, &:focus, &:active{
    color: @black;
  }
}