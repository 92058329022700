.row{
  margin: @rowStdMargin 0;

  .row{margin: 0;}

  &.row--slim{
    margin: 3rem 0;
  }
  &.bottom--slim,
  &.row--slim-bottom{
    margin-bottom: 3rem;
  }
  &.top--slim,
  &.row--slim-top{
    margin-top: 3rem;
  }


  // for colored rows, switch to padding instead of margin
  &.has--primary-color,
  &.has--secondary-color,
  &.has--tertiary-color{
    padding: @rowStdMargin 0;
    margin: 0;

    &.bottom--slim,
    &.row--slim-bottom{
      padding-bottom: 1.5rem;
    }
    &.top--slim,
    &.row--slim-top{
      padding-top: 1.5rem;
    }
  }


  &.row--hidden{
    display: none;
  }


  &.no--gap.wide{
    & > .container{

      & > div{
        padding: 0;

        @media @smL{
          padding: 0 1rem;
        }
      }

    }
  }


  &.img--teaser{
    margin-top: 0;
  }
}

header .row{
  margin: 0;
  padding: 0;
}


.home--conversion .container .container{
  margin: 0 auto;
}


.full-width .joboffer .container{
  display: flex;
  .content50R{
    display: flex;
    align-items: center;
    @media @tabP{
      padding-top: 3rem;
      margin-bottom: 2rem;
    }
  }

  @media @tabP{
    flex-direction: column-reverse;
  }
}
.joboffer .container{
  .content50L{
    padding-left: 0;
    @media @tabP{
      padding-left: 1rem;
    }

    .image{
      margin-bottom: 0;
    }
  }
  background: @tertiaryColor
}



.content50L,
.content33L,
.content33C,
.content66L{
  @media @smL{
    margin-bottom: 2rem;
  }
}






.admin .contentElement{
  position: relative;
}


/**

    These classes can be set in BE via Dropdown.
    You have to know, which color corresponds to which class
    and set an alternative Text color, if necessary.

 */
.has--primary-color{
  background: @primaryColor;
  color: @white;
}
.has--secondary-color{
  background: @secondaryColor;
}
.has--tertiary-color{
  background: @tertiaryColor;
}