#cookie--overlay{
  position: fixed;
  z-index: 960;
  background: rgba(0,0,0,0.8);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: none;

  &.cookie--overlay-visible{
    display: block;
  }
}

#cookie--submission{
  position: fixed;
  z-index: 970;
  bottom: 0;
  left: 0;
  right: 0;
  height: 300px;
  background: @white;
  transform: translate3d(0, 310px,0);
  transition: 0.5s ease-out;

  &.cookie--box-visible{
    transform: translate3d(0, 0,0);
  }
}

.cookie--inner{
  width: 100%;
  height: 100%;
  padding: 2rem;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
}