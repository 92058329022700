/**

  ALL IMAGE CLASSES


 */

// Standard Class for all images | type FIGURE
.image{margin: 0 0 1rem 0;}
header .image{margin: 0;}

// figure's modifier in case of used figcaption
.img--landscape.image--hasCaption{

}


// figure's figcaption itself | type FIGCAPTION
.image--caption{
  font-size: 0.875rem;
}


.img--teaser .image{

  position: relative;

  &::before{
    width: 1px;
    height: 66%;
    background: @white;
    position: absolute;
    bottom: 1rem;
    left: 1rem;
    content: "";
    z-index: 444;
  }

  &::after{
    width: 15%;
    height: 1px;
    background: @white;
    position: absolute;
    bottom: 1rem;
    left: 1rem;
    content: "";
  }

  .image--caption{
    padding: 2rem;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    box-sizing: border-box;
    font-size: 1.75rem;
    color: @white;
    text-shadow: 0 0 6px rgba(0,0,0,0.7);

    @media screen and (max-width: 1480px){
      font-size: 1.5rem;
    }
    @media screen and (max-width: 1366px){
      font-size: 1.25rem;
    }
    @media @tabL{
      font-size: 1rem;
    }
    @media @smL{
      font-size: 1.5rem;
    }
  }

  .image--caption-link{
    color: @white;
  }

  img{
    filter: brightness(66%);
    .interactionTransition();
  }

  &:hover img{
    filter: brightness(100%);
  }
}

.img--teaser .content25LC{
  .image{
    &::before{
      height: 33%;
    }
  }
}

.img--teaser .content25RC,
.img--teaser .content25R{
  .image{
    &::before{
      height: 33%;
      left: auto;
      right: 1rem;
    }
    &::after{
      right: 1rem;
      left: auto;
    }
    .image--caption{
      text-align: right;
    }
  }
}

.img--teaser .content25R{
  .image{
    &::before {
      height: 66%;
    }
  }
}


  /**
    * Standard Image
    *
   */

// class of standard image's figure. | type FIGURE
// That is the outer wrapper
.image--standard{

  // inner wrapper | type DIV
  .image--wrapper{}

  // IF LIGHTBOX: lightbox-link | type A
  .chocolat-image{}

  // image itself | type IMG
  .image--item{}

}

// figure's modifier class in case of Lightbox is active
.image--standard-lightboxed{}








/**
  * Linked Image        AKTUALLY NOT WORKING - Edith cannot set a linked image -.-
  *
 */

// class of image's figure. | type FIGURE
// That is the outer wrapper
.image--linked{

  // inner wrapper | type DIV
  .image--wrapper{}

  // image's link | type A
  .image--link{}

  // image itself | type IMG
  .image--item{}
}

// figure's modifier class in case of link in new tab
.image--linked-extern{}