.header{
  position: fixed;
  z-index: 555;
  width: 100%;
  top: 0;
  left: 0;
  padding-left: 13vw;
  box-sizing: border-box;
  height: @topBarHeight;
  background: @darkMedGrey;
  margin: 0;
  .interactionTransition();

  .container{
    height: 100%;
  }

  @media @tabP{
    padding-left: 0;
    height: 80px;
  }
}

.container > .header--identity{
  width: 50%;
  float: right;

  img{
    height: @topBarHeight;
    float: right;
    .interactionTransition();
  }
}

.scrolled .container > .header--identity{
  img{
    height: @topBarScrolledHeight;
  }
}


.desktop-contacts{
  font-family: @headlineFont;
  .navBarWidth();
  height: 100%;
  background: @darkGrey;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  display: flex;
  text-align: left;
  align-items: center;


  a{
    display: block;
    padding: 0.33rem 0;
    color: @lightGrey;
    line-height: 1;

    .a--icon{display: none;}

    @media @tabL{
      .a--entry{display: none;}
      .a--icon{
        display: block;
      }
    }


    @media @tabL{
      position: absolute;
      top: 0;
      width: 50%;
      bottom: 0;
      text-align: center;
      font-size: 2rem;
      padding-top: 2.75rem;
      left: 0;
      .interactionTransition();

      & + a{
        left: 50%;
        border-left: 1px solid @medGrey;
        box-sizing: border-box;
      }
    }
  }
}

.scrolled .desktop-contacts a{
  @media @tabL{
    padding-top: 1.5rem;
  }
}



.mobile-phone{
  display: none;
}

@media @momentToShowTheHamburger{
  .desktop-contacts{
    display: none;
  }

  .mobile-phone{
    display: flex;
    position: absolute;
    top: 0;
    left: 70px;
    bottom: 0;
    width: 66px;
    border-left: 1px solid @medGrey;
    border-right: 1px solid @medGrey;


    a{
      display: flex;
      width: 100%;
      height: 100%;
      align-items: center;

      .fa{
        color: @whiteGrey;
        font-size: 2rem;
        display: inline-block;
        width: 100%;
        text-align: center;
      }
    }
  }

  .container > .header--identity{

    width: 66%;

    img{
      height: auto;
      max-height: 80px;
      width: auto;
    }
  }
}

@media @smL{

  .container > .header--identity img{
    max-height: none;
    width: 100%;
    height: auto;
  }

}



.scrolled .header{
  height: @topBarScrolledHeight;
}