.socials{
  position: absolute;
  bottom: 10%;
  left: 0;
  right: 0;

  @media @tabL{
    bottom: 5%;
  }

  @media @tabP{
    bottom: 20%;
  }

  @media @smL{
    bottom: 10%;
  }
}

.socials--inner{
  width: 100%;
  text-align: center;

  a{
    display: inline-block;
    width: 50px;
    text-align: center;
    padding: 1rem 0;
    color: @lightGrey;
    font-size: 2rem;
    .interactionTransition();

    &:hover,
    &:active{
      color: @primaryColor;
    }
  }
}