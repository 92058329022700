.maps{

  img{
    width: 100%;
    height: auto;
    display: block;
  }
}

// Form Styles
.form--contact{

  .form--line{
    display: table;
    width: 100%;
    margin-bottom: 1rem;

    &.field--required{
      .form--label{
        color: @warningColor;
      }

      .form--input{
        border-color: @warningColor;
      }
    }
  }

  .form--label{
    width: 25%;
    float: left;
    line-height: 44px;

    @media @smL{

      display: block;
      width: 100%;
      font-size: 0.75rem;
      line-height: 1;
      float: none;
    }
  }

  .form--input, .form--textareafield{
    width: 74%;
    float: right;
    box-sizing: border-box;
    height: 44px;
    font-family: @stdFont;
    font-size: 0.75rem;

    &:focus{
      border-color: @black;
    }

    @media @smL{

      display: block;
      width: 100%;
      line-height: 1;
      float: none;
    }
  }
  .form--textareafield{
    padding-top: 0.66rem;
    height: 8rem;
  }

  .form--submit{

    .button--submit{

      background: @black;
      font-weight: 600;
      padding: 0.5rem 1rem;
      color: @white;
    }
  }


  .form--adv{
    display: flex;
    flex-direction: row-reverse;

    .form--label{
      width: 74%;
      line-height: 1.6;
    }

    .form--input-checkbox{
      width: 10%;
      margin-left: 15%;
      height: 26px;
    }

    @media @smL{

      .form--label{
        width: 80%;
      }

      .form--input-checkbox{
        width: 10%;
        margin-left: 5%;
        margin-right: 5%;
      }
    }
  }
}