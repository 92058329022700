/**

  ALL VIDEO CLASSES


 */

// videos container | type DIV
.video{}
// videos youtube element | type DIV
.video--yt{}



// video wrapper for responsive behavior
.video--wrapper{
  position: relative;
  padding-bottom: 56.25%;
  height: 0;

  iframe{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }


  .video--not-allowed{
    position: relative;

    img{
      width: 100%;
      display: block;
      margin-bottom: 0;
    }

    .privacy--video{
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      box-sizing: border-box;
      display: flex;
      flex-wrap: wrap;
      text-align: center;
      align-items: center;
      padding: 0;
      margin: 0;
      align-content: center;
      background: rgba(0,0,0,0.6);
      .interactionTransition();

      &:hover{
        background: rgba(0,0,0,0.8);
      }

      p, a{
        display: block;
        width: 100%;
        box-sizing: border-box;
        padding: 0 2rem;
        color: @white;
      }
    }
  }
}